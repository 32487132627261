/* ALL LOADERS */

.loader {
  width: 100px;
  height: 100px;
  border-radius: 100%;
  position: relative;
  margin: 0 auto;


  span {
    display: inline-block;
    width: 20px;
    height: 20px;
    border-radius: 100%;
    margin: 35px 5px;
    -webkit-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    -moz-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);

    &:nth-child(1){
      animation: bounce 1s ease-in-out infinite;
    }

    &:nth-child(2){
      animation: bounce 1s ease-in-out 0.33s infinite;

    }

    &:nth-child(3){
      animation: bounce 1s ease-in-out 0.66s infinite;
    }

  }

  &.button {
    width: 22px;
    height: 20px;
    border-radius: 100%;
    position: relative;
    margin: 0 auto;
    float: right;

    span {
      width: 5px;
      height: 5px;
      margin: 9px 1px;

      &:nth-child(1){
        animation-name: bounce-button;
      }

      &:nth-child(2){
        animation-name: bounce-button;
        background-color: #FFFFFF;
      }

      &:nth-child(3){
        animation-name: bounce-button;
      }
    }
  }
}



.loader span:nth-child(1){
  animation: bounce 1s ease-in-out infinite;
  background-color: #FFFFFF;
}

.loader span:nth-child(2){
  animation: bounce 1s ease-in-out 0.33s infinite;
  background-color: #3297FF;
}

.loader span:nth-child(3){
  animation: bounce 1s ease-in-out 0.66s infinite;
  background-color: #FFFFFF;
}

@keyframes bounce{
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-20px);
    -ms-transform: translateY(-20px);
    -o-transform: translateY(-20px);
    transform: translateY(-20px);
  }
}

@keyframes bounce-button{
  0%, 75%, 100%{
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
    transform: translateY(0);
  }

  25%{
    -webkit-transform: translateY(-5px);
    -ms-transform: translateY(-5px);
    -o-transform: translateY(-5px);
    transform: translateY(-5px);
  }
}
