@font-face {
    font-family: Poppins-Regular;
    src: url('/styles/homepage/fonts/poppins/Poppins-Regular.ttf');
  }

  @font-face {
    font-family: Poppins-Bold;
    src: url('/styles/homepage/fonts/poppins/Poppins-Bold.ttf');

  }

  @font-face {
    font-family: Nunito-Regular;
    src: url('/styles/homepage/fonts/NunitoSans/NunitoSans-Regular.ttf');
  }

  @font-face {
    font-family: Nunito-Bold;
    src: url('/styles/homepage/fonts/NunitoSans/NunitoSans-Bold.ttf');
  }

body, h2, h3, h1, h4, label, .input , button{
    font-family:Nunito-regular, -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji" !important;
}

h2{
    font-family: Nunito-Bold;
    font-size: 20px;
    margin: auto;
    text-align: center;
    font-weight: bold;
    padding: 5px 0 0 0;
    color: #333333;
    position: relative;
    left: 0px;
}

.badge{
    margin:5px 2px;
}

pre{
    font-family: Nunito-regular !important;
    font-size: 18px !important;
}


#mainNav{
    background: -moz-linear-gradient(top, rgba(0,0,0,0.65) 26%, rgba(0,0,0,0) 100%);
    background: -webkit-linear-gradient(top, rgba(0,0,0,0.65) 26%,rgba(0,0,0,0) 100%);
    background: linear-gradient(to bottom, rgba(0,0,0,0.65) 26%,rgba(0,0,0,0) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 );
    height: 100px;
    transition: height 0.3s;
    color:#fff;
    #logo img{
        width: 165px;
        top: -6px;
        position: relative;
    }
    &.navShrink{
        background:#212529;
        transition: height 0.3s;
        @media (min-width: $bp-tablet) {
          height: 70px;
        }
    }

    .navbar-collapse {
      @media (max-width: 992px) {
        background-color: black;
        padding: 15px;
        margin-top: .5em;
        position: absolute;
        width: calc(100% - 30px);
      }
    }
    .navbar-nav {
      @media (max-width: 1024px)  {
        margin-bottom: 0.6em;
      }
    }
    .fa-stack {
      @media (max-width: 1024px)  {
        width:1em;
        margin: .8em 0 !important;
      }
    }

}



.navbar-brand span {
    color: #3297ff;
}

.controls h1{
    color:#fff;
    font-family: Nunito-bold;
    font-weight: 700;
}

.controls {
  .badge{
     min-width: 3.7rem;
     padding-top: .75em;
     padding-bottom: .75em;
     margin-left: 0px;
     margin-right: 0px;
   }
   h1 {
     .badge{

        padding-top: .25em;
        padding-bottom: .25em;
        font-weight: normal;
        font-size: 45%;
      }
   }

}


.filter {
  .badge,
  .switch{
    width:100%;
    min-width: auto !important;
  }
}


a.nav-link:not(.btn){
    color:#fff !important;
}

@keyframes nav {
    0% {
        width: 200px;
    }
    100% {
        width: 150px;
    }
}

.modal-content{
    font-size: 18px;
    padding: 10px;
    h4{
        font-size: 18px;
        font-weight: bold;
    }
}

.controls {
    padding: 110px 0 5px 0;

    @media print {
      padding-top:40px;
    }

    margin-bottom: 20px;
    background-size: cover;
    background-position: center;
    width: 100%;


    label{
        color: #fff;
        margin: .5rem 0 .5rem 0;
    }

    @media (max-width: 768px) {
      .switch,
      .badge {
        width:100%;min-width: 61px;
        min-width: 65px;
      }
    }

}
.fill-bg {
  .controls {
    margin-bottom: 0px;
  }
  main {
    .container {
      padding-top: 20px;
    }
  }
}
.row-seven-padding
{
  display: flex;
  flex-wrap: wrap;
  margin-right: -7px;
  margin-left: -7px;

  .col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12,
  .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6,
   .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
    padding-right: 7px;
    padding-left: 7px;
  }
}

.filter {
    padding: .5rem 10px 1.5rem 10px;
    margin: 10px 0;
    border-radius: 5px;
    background-color: rgba(0, 0, 0, 0.53);
    min-width: 200px;
    flex-grow: 1;
    flex-basis: 0;
    .link{
        color:#fff;
    }
}
.flex-basis-90 {
    flex-basis: 90px;
}
div[class*="-control"] > div:first-child {
  padding-left:2px;
  padding-right:2px;
}

div[class*="-multiValue"] {
  float:left;
}

div[class*="-control"] > div:first-child >  div:last-child {
  float:left;
}

div#test p:first-child

.btn-dark{
  background-color: #6d7e8a !important;
  border: none;
}

.switch-on{
  color:rgba(255, 255, 255, 0.6) !important;
}

.switch-off{
  color:rgba(0, 0, 0, 0.4) !important;
  text-shadow: 0px 1px 1px #888888;
}
.intro-text {
  @media (min-width: 576px) {
    padding-top: 100px;
  }
}
main,
.intro-text {
  min-height: calc(100vh - 100px);
  @media (min-width: 576px) {
    min-height: calc(100vh - 115px);
  }
}
.filter {
  .toast{
      position: fixed;
      z-index: 1050;
      top: 60px;
      right: 10px;
      color: #fff;
  }
}
[data-count]:after{
  position: absolute;
  top: -0.6em;
  content: attr(data-count);
  font-size: .6em;
  padding: .7em;
  border-radius: 999px;
  line-height: .75em;
  color: white;
  background-color: #007bff;
  text-align: center;
  min-width: 2.4em;
  font-weight: bold;
}
.nav-link[data-count]:after {
    top: -0.2em;
    left: 8.2em;
}
.fa-stack[data-count]:after{
  left:2.5em;
}
.after-ml-1[data-count]:after{
  margin-left:.25em;
}

.danger[data-count]:after{
  background:rgba(255,0,0,.85);
}

.item_count {
    color: #ffffff;
    padding: 5px;
    min-width: 220px;
    width: 50%;
    background-color: #3297FF;
    margin: auto;
    border-radius: 30px;
    text-align: center;
    font-size: 24px;
    margin: 10px auto 20px auto;
    -webkit-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    -moz-box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
    box-shadow: 5px 5px 10px -1px rgba(0,0,0,0.35);
}

.user-name{
    padding: 10px 10px 10px 0px;
    & i{
      padding: 5px;
      @media (max-width: 1024px)  {
        padding-left:0px;
      }
    }
}
.modal-spinner {
  height: 100vh;
  width: 100vw;
  position:fixed;
  top:0px;
  left:0px;
  background-color: rgba(0,0,0,.5);
  z-index: 1600;
}

.cookie-consent {
  bottom: 0;
  position: fixed !important;
  right: 0;
  border-radius: 4px;
  z-index: 1500;
  h2 {
    color: #fff;
    margin: 0px;
    font-size: 2em;
  }
  .fa{
    cursor: pointer;
    font-size: 24px;
  }
  p{
    font-size: 14px;
  }
  .btn {
    font-weight: 400;
  }
}

.toast {
  &.notications {
    background-color: #fff;
    border-radius: .45em;
    -webkit-box-shadow: 5px 5px 15px 5px #000000;
    box-shadow: 5px 5px 15px 5px #000000;
    overflow-y: auto;
    max-height: 80vh;

    &:after {
        content: '';
        position: absolute;
        display: block;
        width: 0;
        z-index: 1;
        border-style: solid;
        border-color: #fff transparent;
        border-width: 0 10px 10px;
        top: -9px;
        right: 1em;

    }
    ul {
      list-style: none;
    }
    .toast-header {
      display: none;
    }
    .toast-body {
      padding: 0px;

      ul {
        padding: 0px;
        margin-bottom: 0px;
        li {
          padding: 1em;
          border-bottom:solid 1px #ddd;
          background-color:#fff;
          transition: background-color 300ms linear, color 300ms linear;
          &:first-child {
            border-radius: .45em .45em 0 0;
          }
          &:last-child {
            border-bottom: none;
            border-radius: 0 0 .45em .45em;
          }
          &:not(:last-of-type) {
            &:not(.no-hover) {
              &:hover {
                background-color: #0275d8;
                color: white;
                p,a {
                  color:white;
                }
                .heading {
                  color: white;
                }
              }
            }
          }
          p {
            margin-bottom: .3em;
            line-height: 1.5;
            cursor: default;
            &:last-child {
              margin-bottom: 0em;
            }
          }
        }
      }
      p,a {
        color:#929292;
      }
      .heading {
        color:black;
        font-size: 24px;
      }
    }
  }

}
.pull-right-desktop {
    float:right;
}

  @media (max-width: $bp-tablet) {
    .container {
      padding-left: 5px !important;
      padding-right: 5px !important;
    }

    .filter {
      padding-left: 0px !important;
      padding-right: 0px !important;
    }

    .pull-right-desktop {
        float:none;
    }

  }
  .abscenter {
    position: absolute;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 100;
    background-color: red;
  }

  .custom-dropdown {
    button {

      align-items: center;
      justify-content: space-between;
      border-radius: 4px;
      border-style: solid;
      border-width: 1px;
      cursor: pointer;
      display: flex;
      &.btn-primary {
        background-color: transparent !important;
        border-color: hsl(0,0%,80%);

        &:after {
          color: hsl(0,0%,80%);
        }
        div {
            color: #333333;
        }
      }


    }
    .dropdown-item {
      padding-left: 0.75em;
      padding-right: 0.75em;
      &:before {
        font-family: FontAwesome;
        display: inline-block;
        font: FontAwesome;
        font-size: inherit;
        text-rendering: auto;
        -webkit-font-smoothing: antialiased;
        padding-right: 0.5em;
        content: "\f067";
      }
      &.active {
        &:before {
          padding-right: 0.4em;
          content: "\f00c";
        }
      }
    }
  }
