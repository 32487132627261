

@import '../node_modules/bootstrap/scss/_functions.scss';
@import '../node_modules/bootstrap/scss/_mixins.scss';
@import '../node_modules/bootstrap/scss/_variables.scss';


@media screen and (max-width: $bp-tablet)  {
  .custom-responsive {
    thead,
    th {
      display:none;
    }
    tr,
    td,
     {
      display:block;
      width:100%;
    }
    td {
        border-bottom: none !important;
        border-top: none !important;
    }

  }
}

@media (max-width: 1000px)  {
  .pagination {
    margin-top: 1rem!important;
  }
}


.double-rowed {
    tbody+tbody {
        border-top : none !important;
    }

    tbody {
      border-bottom: 1px solid #dee2e6;

      &:nth-of-type(odd) {
        tr {
          background-color: rgba(0,0,0,.05);
        }
      }
      &:hover {
        tr {
          background-color: rgba(0,0,0,.075);
        }
      }
      tr {

        th,
        td {
          border: none;
        }
      }

    }
}

@media print {
  .rs-table {
      height:auto !important;
  }
  .rs-table-header-row-wrapper {
    height:40px;
  }

  .rs-table-row-header  {
    width:100% !important;
    min-width: auto !important;
    .rs-table-cell-header:nth-of-type(1) {
      .rs-table-cell {
        width:17% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(2) {
      .rs-table-cell {
        left:17% !important;
        width:6% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(3) {
      .rs-table-cell {
        left:23% !important;
        width:9% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(4) {
      .rs-table-cell {
        left:32% !important;
        width:69% !important;
      }
    }
    .rs-table-cell-header:nth-of-type(5) {
      display: none;
    }
  }

  .rs-table-body-row-wrapper  {
    height:auto !important;
    .rs-table-body-wheel-area {
      position: initial !important;
        width:100% !important;;
    }
    .rs-table-row {
      width:100% !important;
      height:auto !important;
      min-width: auto !important;
      transform: none !important;
      display: flex;
      flex-direction: column;
      position: initial;
      .rs-table-cell-group {
          flex-grow: 1;
          display: flex;

          .rs-table-cell {
            height:auto !important;
            position: initial;
            .rs-table-cell-content {
                height:auto !important;
                width:100% !important;
            }

          }
        }
    }
    .rs-table-cell:nth-of-type(1) {
        width:17% !important;
    }
    .rs-table-cell:nth-of-type(2) {
      left:17% !important;
      width:6% !important;
    }
    .rs-table-cell:nth-of-type(3) {
      left:23% !important;
      width:9% !important;
    }

    .rs-table-cell:nth-of-type(4) {
      left:32% !important;
      width:69% !important;

    }

    .rs-table-cell:nth-of-type(5) {
      display: none;
    }

  }
  .table-list {
    .rs-table-cell:nth-of-type(4) {
      left:32% !important;
      width:69% !important;
      display: flex;
      flex-direction: row;
      align-items: center;
      &:before {
        flex-grow: 0;
        order: 2;
        content: ' ';
        border:solid 1px grey;
        display: block;
        width:30px;
        height:30px;
        border-radius: 2px;
        margin-right: 8px;
      }
      &:after {
        content: ' ';
        flex-grow: 1;
        order: 3;
        border:solid 1px grey;
        display: block;
        width:120px;
        height:70px;
        border-radius: 2px;
        margin-right: 8px;
      }
      &.constrain-print {
          .rs-table-cell-content {
            width:50% !important;
          }
      }
      .rs-table-cell-content {
        order: 1;
        width:50% !important;
      }
    }
  }
}

$table-cell-padding : 0.75rem;


//
// Basic Bootstrap table
//
.md-table {
  & > div > p {
    line-height: 1.5rem;
    margin-bottom: 0px;
  }
  img {
    max-width: 100%;
  }
  table {
    width: 100%;
    margin-bottom: $spacer;
    color: $table-color;
    background-color: $table-bg; // Reset for nesting within parents with `background-color`.

    th,
    td {
      padding: $table-cell-padding;
      vertical-align: top;
      border-top: $table-border-width solid $table-border-color;
    }

    thead th {
      vertical-align: bottom;
      border-bottom: (2 * $table-border-width) solid $table-border-color;
    }

    tbody + tbody {
      border-top: (2 * $table-border-width) solid $table-border-color;
    }
  }




  // Border versions
  //
  // Add or remove borders all around the table and between all the columns.

  table {
    border: $table-border-width solid $table-border-color;

    th,
    td {
      border: $table-border-width solid $table-border-color;
    }

    thead {
      th,
      td {
        border-bottom-width: 2 * $table-border-width;
      }
    }
  }



  // Zebra-striping
  //
  // Default zebra-stripe styles (alternating gray and transparent backgrounds)

  table {
    tbody tr:nth-of-type(#{$table-striped-order}) {
      background-color: $table-accent-bg;
    }
  }


  // Hover effect
  //
  // Placed here since it has to come after the potential zebra striping

  table {
    tbody tr {

      &:hover {
        color: $table-hover-color;
        background-color: $table-hover-bg;
      }
      
    }
  }


  // Table backgrounds
  //
  // Exact selectors below required to override `.table-striped` and prevent
  // inheritance to nested tables.



}

.flex-table {
  display: flex;
  flex-flow: row wrap;

  transition: 0.5s;
  &.no-records {
    border-right: solid 1px $table-border;
    border-bottom: solid 1px $table-border;
  }
  &.table-row {
    margin-left: 0px;
    margin-right: 0px;
    &.hover-effect {
      &:hover {
        color: $iatg-hover;
      }
    }
    &:nth-of-type(even) {
      background: rgba(0, 0, 0, 0.05);
    }

    &:nth-of-type(odd) {
      background: #ffffff;
    }
    &:hover {

      background: rgba(0,0,0,.075);

    }

  }
  &:first-of-type .flex-row {
    border-color: $table-border;
  }
  &.table-row:nth-child(even) .flex-row {
    /*background-color: rgba(0,0,0,.05);*/

  }



}

.flex-row {
  flex-basis: calc(100% / 4);
  padding: .5rem 0.75rem;
  border-bottom: solid 1px $table-border;
  border-left: solid 1px $table-border;


  &:last-of-type {
      border-right: solid 1px $table-border;
  }



  [data-count]:after {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }



  &.module {
    flex:0 0 110px;

  }
  &.section {
    flex:0 0 150px;

  }
  &.importance {
    flex:0 0 110px;
    .badge {
        width: 100%;
        display: block;
        margin-top: 0px;
    }
  }

  &.text {
    max-width:590px;
    img {
      max-width: 100%;
    }
  }

  &.title {
    flex-grow:1;
  }
  &.actions {
    flex:0 0 120px;
  }

}

.header {
  border-top: solid 1px $table-border;
  background-color: white;
}

.rowspan {
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: center;
}

.column {
  display: flex;
  flex-flow: column wrap;
  width: 75%;
  padding: 0;
  .flex-row {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    padding: 0;
    border: 0;
    border-bottom: solid 1px $table-border;


  }
}



.flex-cell {
  flex-basis: calc(100% / 3); //1px = border right
  text-align: center;
  padding: 0.5em 0.5em;
  border-right: solid 1px $table-border;
  //flex: 1 1 33.3%;
  &:last-child {
    // border-right: 0;
  }
}

/*@media all and (max-width: 767px) {
  .flex-row {
    width: calc(100% / 3); //1px = border right

   &.first {
     width: 100%;
   }
  }

  .column {
    width: 100%;
  }
}*/
@media all and (min-width: 900px) {
  .clamp-col  {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    overflow: hidden;
    white-space: break-spaces;
    text-overflow: initial;

    div {
      display: inline;
    }
  }
}

@media all and (max-width: 900px) {
  .strong-single-col {
    font-weight: bold;
  }

  .flex-table {
    border-left: solid 1px $table-border;
    border-right: solid 1px $table-border;
    border-top: solid 1px $table-border;

    &:last-of-type {
      border-bottom: solid 1px $table-border;
    }
    &:nth-of-type(even) {
      background: rgba(0, 0, 0, 0.05);
    }

    &:nth-of-type(odd) {
      background: #ffffff;
    }

    .flex-row {
      border-bottom: 0;
    }

    .flex-row:last-of-type {
      /*border-bottom: solid 1px $table-border;*/
    }
  }

  .header {
    display: none;
  }

  .flex-row {
    flex-basis: 100% !important;

    border-bottom: none;
    border-left: none;


    &:first-of-type {
      padding-top: 1rem;
    }
    &:last-of-type {
      padding-bottom: 1rem;
      border-right: none;
    }
     &.first {
       flex-basis: 100%;
       border-bottom: solid 1px $table-border;
     }

     .btn,
     .badge {
       max-width: 100px;
       float: left;
       clear: both;
     }

  }

  .column {
    width: 100%;
    .flex-row {
      border-bottom: solid 1px $table-border;
    }
  }

  .flex-cell {
    flex-basis: 100%; //1px = border right

  }

  .md-table {
    table , tr, th,  td {
      display: block;
      width:100%;
    }
  }
}
