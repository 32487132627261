.grecaptcha-badge {
    visibility: hidden;
}

.nav-link {
  &.btn {
    display: inline-block;
  }
}

.input-group-text {
    min-width: 2.6rem;
    justify-content: center;
}

pre {
  white-space: break-spaces;
}
.white {
  color: #fff;
}
.blue {
  color: #007bff;
}
.link-color {
  color: #0056b3;
}
.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.badge {
  padding: .5em .8em;
}

.popover {

  &.wide {
    min-width:40%;
    width:auto !important;
    max-width:60% !important;
  }

}

.badge{
  margin:3px 0;
}

.loading-spinner {
  height: 100px;
}
.big-top-pad {
  padding-top: 120px

}
.flex-desktop {
  display: flex;

  @media (max-width: 1000px)  {
    display: inline-block;
  }
}

.hidden-tablet-landscape {
  display: initial !important;
  @media (max-width: 1024px)  {
    display:none !important;
  }
}

.visible-tablet-landscape {
  display: none !important;
  @media (max-width: 1024px)  {
    display:initial !important;
  }
}

.visible-desktop {
  display: initial;

  @media (max-width:  1000px)  {
    display:none;
  }
}

.hidden-desktop {
  display: none;

  @media (max-width: 1000px)  {
    display:initial;
  }
}
.pointer {
  cursor: pointer !important;
}
.float-badge {
  position: relative;
  padding-right: 24px !important;
  .badge {
    position: absolute;
    top: 0px;
    right: 0px;
  }
}
