.carousel {

  @media  (min-width: $bp-tablet) {
    padding:0px 30px 50px 30px;
  }
  .carousel-indicators {
    li {
      background-color: #5a6268;
    }
  }
  .carousel-control-prev {
    left:-2.5rem;
  }
  .carousel-control-next {
    right:-2.5rem;
  }
  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    background-image: none;
    font-family:  FontAwesome;
    color: #5a6268;

  }
  .carousel-control-prev-icon {
    &:before {
      content: "\f053";
    }
  }
  .carousel-control-next-icon {
    &:before {
      content: "\f054";
    }
  }
}
.padding-0 {
  .modal-content {
    padding-left: 0px;
    padding-right: 0px;
    .modal-header {
      margin-left: 10px;
      margin-right: 10px;
    }
    .modal-body {
      padding-left: 0px;
      padding-right: 0px;

      .carousel-item {
        padding-left: 1rem;
        padding-right: 1rem;
      }
    }

  }
}
