.css-26l3qy-menu {
  z-index:100 !important;
}
 .css-yt9ioa-option,
 .css-9gakcf-option,
 .css-1n7v3ny-option {

   &:before {
     font-family: FontAwesome;
     display: inline-block;
     font:  FontAwesome;
     font-size: inherit;
     text-rendering: auto;
     -webkit-font-smoothing: antialiased;
     padding-right: 0.4em;
   }

 }

.css-yt9ioa-option,
.css-1n7v3ny-option {
    &:before {
      content: "\f067";
    }
 }

.css-9gakcf-option {
  /*display: none !important;*/
  &:before {
    content: "\f00c";

  }
}
